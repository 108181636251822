// src/components/SalesRepsHalf.tsx
import React, { useState } from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import Reps from "./Reps";
import accessURL from "../../../../../components/utils/access";
import { useDateFilter } from "../../../../../contexts/dashboard/DateFilterContext";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface SalesRep {
  salesRepID: number;
  salesRepName: string;
  totalReports: number;
}

interface SalesRepResponse {
  status: string;
  code: number;
  data: SalesRep[];
}

const fetchReps = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  name?: string
): Promise<SalesRepResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(
    `metrics/getManagersSalesRep?name=${name}&${params.toString()}`
  );
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
  };
};

const SalesRepsHalf: React.FC<{ name?: string }> = ({ name }) => {
  const dateFilter = useDateFilter();

  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading } = useQuery({
    queryKey: ["repsandreports", startDate, endDate, selectedYear, name],
    queryFn: () => fetchReps(startDate, endDate, selectedYear, name),
  });

  return (
    <section>
      <div className="my-4 p-3 flex justify-center lg:justify-start">
        <Link
          to={`/admin/${email}/salesreps/${name}`}
          className="inline-flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
        >
          See All Salesreps
          <HiOutlineArrowRight className="ml-2 text-lg" />
        </Link>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <div className="w-10 h-10 border-4 border-blue-500 border-dotted rounded-full animate-spin"></div>
        </div>
      ) : data?.data.length === 0 ? (
        <div className="text-center text-gray-600 text-lg">
          No sales representatives assigned to this manager 📊
        </div>
      ) : (
        <Reps name={name} reps={data?.data} />
      )}
    </section>
  );
};

export default SalesRepsHalf;
