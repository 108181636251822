import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import mammoth from "mammoth";
import { FileText } from 'lucide-react';

interface WordViewerProps {
  url: string;
  onClose: () => void;
}

const WordViewer: React.FC<WordViewerProps> = ({ url, onClose }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState<string>("");

  useEffect(() => {
    const loadWordDocument = async () => {
      console.log("Starting document load for URL:", url);

      if (!url) {
        console.error("No URL provided");
        setError("No document URL provided");
        setIsLoading(false);
        return;
      }

      try {
        console.log("Fetching document...");
        const response = await fetch(url);

        if (!response.ok) {
          console.error("Fetch failed:", response.status, response.statusText);
          throw new Error(`Failed to fetch document: ${response.statusText}`);
        }

        console.log("Document fetched, converting to array buffer...");
        const arrayBuffer = await response.arrayBuffer();

        console.log("Converting document with mammoth...");
        // Removed the options object to fix TypeScript error
        const result = await mammoth.convertToHtml({ arrayBuffer });

        console.log("Conversion complete:", result.messages);

        if (result.value) {
          setContent(result.value);
        } else {
          throw new Error("No content generated from document");
        }
      } catch (err) {
        console.error("Error processing document:", err);
        setError(
          err instanceof Error ? err.message : "Failed to load document"
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadWordDocument();
  }, [url]);

  const handleDownload = () => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
      style={{ zIndex: 9999 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-white p-6 rounded-lg shadow-xl relative max-w-4xl w-full mx-4 z-[60]"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Download Document
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2 w-8 h-8 flex items-center justify-center"
          >
            ×
          </button>
        </div>

        {isLoading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
            <div className="ml-4">Loading document...</div>
          </div>
        )}

        {error && (
          <div className="flex flex-col items-center justify-center p-8 space-y-4">
            <FileText className="w-12 h-12 text-blue-500" />
            <p className="text-gray-600 text-center">
              This document cannot be previewed in the browser. This can happen with older Word formats or complex documents.
            </p>
            <p className="text-gray-600 text-center">
              Please click the "Download Document" button above to open it in your preferred word processor.
            </p>
          </div>
        )}

        {!isLoading && !error && content && (
          <div
            ref={containerRef}
            className="overflow-auto max-h-[80vh] bg-white rounded-lg p-6"
          >
            <div
              dangerouslySetInnerHTML={{ __html: content }}
              className="prose prose-sm sm:prose lg:prose-lg"
            />
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default WordViewer;