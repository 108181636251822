import React, { useState, useEffect } from "react";
import Calendar from "./calendarComponent/Calendar";
import Schedules from "./schedules/Schedules";

const Slots: React.FC<{ get?: (date: Date) => void }> = ({ get }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    if (get) {
      get(selectedDate);
    }
  }, [selectedDate]);
  
  return (
    <section className="w-full">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
        <div className="col-span-2 md:col-span-3 lg:col-span-3">
          <Calendar selectedDate={selectedDate} onChange={setSelectedDate} />
        </div>
        <div className="col-span-1 md:col-span-2 lg:col-span-2">
          <Schedules selectedDate={selectedDate} />
        </div>
      </div>
    </section>
  );
};

export default Slots;
