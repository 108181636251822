import React, { useState } from "react";
import { BaseURL } from "../../../components/utils/url";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const Email: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await BaseURL.post("auth/forgetPassword", {
        email: email,
      });

      if (response.status === 200) {
        toast.success("Reset link sent to your email!", {
          position: "top-right",
          autoClose: 3000,
        });

        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        toast.error("Email does not exist!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Failed to send reset link. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto p-6">
      <h2 className="text-2xl font-bold mb-4">Forgot Password</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          className="w-full px-4 py-2 rounded border focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-full"
          required
        />

        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 disabled:opacity-50"
        >
          {isLoading ? "Sending..." : "Reset Password"}
        </button>
      </form>
    </div>
  );
};

export default Email;
