import React, { useState, useRef, useEffect } from "react";
import accessURL from "../../../../../../../components/utils/access";

interface ManagerData {
  ID: number;
  companyID: string;
  name: string;
  role: string;
  email: string;
  cpa: number | null;
  salesAchieved: number | null;
  cpaGrowthPeriod: number | null;
  salesGrowthPeriod: number | null;
}

interface ManagersProps {
  onSelect: (rep: ManagerData | null) => void;
  assignedManager: string;
}

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

const Managers: React.FC<ManagersProps> = ({ onSelect, assignedManager }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedManager, setSelectedManager] = useState<ManagerData | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [salesReps, setSalesReps] = useState<ManagerData[]>([]);
  const [pagination, setPagination] = useState<PaginationInfo | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchManagers = async (page: number = 1) => {
    setIsLoading(true);
    const response = await accessURL.get(`managers?page=${page}&per_page=10`);
    if (response.data.status === "success" && response.data.code === 200) {
      // Filter out the assignedManager
      const filteredManagers = response.data.data.managers.filter(
        (manager: ManagerData) => manager.name !== assignedManager
      );

      setSalesReps((prevReps) => [...prevReps, ...filteredManagers]);
      setPagination(response.data.data.pagination);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchManagers();
  }, []);

  const handleSelect = (rep: ManagerData) => {
    setSelectedManager(rep);
    onSelect(rep);
    setIsOpen(false);
    setSearchTerm("");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredReps = salesReps.filter((rep) =>
    rep.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLoadMore = () => {
    if (pagination && pagination.current_page < pagination.last_page) {
      fetchManagers(pagination.current_page + 1);
    }
  };

  return (
    <div className="w-full relative" ref={dropdownRef}>
      <div
        className="w-full px-3 py-2 border border-gray-300 rounded-md cursor-pointer bg-white flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          {selectedManager ? selectedManager.name : "Select a Manager"}
        </span>
        <span className="text-gray-400">{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="p-2">
            <input
              type="text"
              placeholder="Search sales representatives..."
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="max-h-60 overflow-y-auto">
            {isLoading && salesReps.length === 0 ? (
              <div className="p-4 text-center">
                <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              </div>
            ) : (
              <>
                {filteredReps.map((rep) => (
                  <div
                    key={rep.ID}
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSelect(rep)}
                  >
                    {rep.name}
                  </div>
                ))}
                {pagination &&
                  pagination.current_page < pagination.last_page && (
                    <div
                      className="p-2 text-center text-blue-500 cursor-pointer hover:bg-gray-100"
                      onClick={handleLoadMore}
                    >
                      {isLoading ? (
                        <div className="inline-block animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blue-500"></div>
                      ) : (
                        "Load More"
                      )}
                    </div>
                  )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Managers;
