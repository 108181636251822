import React, { createContext, useState, useContext, ReactNode } from "react";

// Define the shape of your context data
interface DateFilterContextType {
  startDate: string;
  endDate: string;
  selectedYear: number;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setSelectedYear: (year: number) => void;
}

// Create the context with a default undefined value
const DateFilterContext = createContext<DateFilterContextType | undefined>(
  undefined
);

// Provider component to wrap your app or component
export const DateFilterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );

  return (
    <DateFilterContext.Provider
      value={{
        startDate,
        endDate,
        selectedYear,
        setStartDate,
        setEndDate,
        setSelectedYear,
      }}
    >
      {children}
    </DateFilterContext.Provider>
  );
};

// Custom hook to use the context
export const useDateFilter = () => {
  const context = useContext(DateFilterContext);
  return context;
};
