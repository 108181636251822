import RepReport from "../components/reports/single_reports/salesRepDet/rep_report/RepReport";
import AdminLayout from "../layout/AdminLayout";
import AllReports from "../pages/AllReports";
import AllSalesReps from "../pages/AllSalesReps";
import CreateManager from "../pages/CreateManager";
import CreateSalesRep from "../pages/CreateSalesRep";
import Dashboard from "../pages/Dashboard";
import Personnel from "../pages/Personnel";
import Product from "../pages/Product";
import Reports from "../pages/Reports";
import SingleReports from "../pages/SingleReports";

const AdminRoutes = [
  {
    path: "/admin/:name",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "personnel",
        element: <Personnel />,
      },
      {
        path: "product",
        element: <Product />,
      },
      {
        path: "report",
        element: <Reports />,
      },
      {
        path: "report/:name",
        element: <SingleReports />,
      },
      {
        path: "report/:rep/:salesRepID",
        element: <RepReport />,
      },
      {
        path: "report/reports/all/:salesRepID",
        element: <AllReports />,
      },
      {
        path: "salesreps/:manager",
        element: <AllSalesReps />,
      },
      {
        path: "create-manager",
        element: <CreateManager />,
      },
      {
        path: "create-salesRep",
        element: <CreateSalesRep />,
      },
    ],
  },
];
export default AdminRoutes;
