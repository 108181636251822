import React from "react";
import { useNavigate } from "react-router-dom";
import { Users, BarChart3, ArrowRight } from "lucide-react";

interface ManagerCardProps {
  name: string;
  count: number;
  email: string;
}

const ManagerCard: React.FC<ManagerCardProps> = ({ name, count, email }) => {
  const navigate = useNavigate();

  const handleViewReport = () => {
    navigate(`/admin/${email}/report/${name}`);
  };

  return (
    <div className="group relative bg-white rounded-xl p-6 shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100">
      {/* Top Section */}
      <div className="flex items-start justify-between mb-6">
        <div className="flex-1">
          <h3 className="text-xl font-semibold text-gray-900 mb-1 line-clamp-1">
            {name}
          </h3>
        </div>
        <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-blue-50">
          <Users className="w-6 h-6 text-blue-600" />
        </div>
      </div>

      {/* Stats Section */}
      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg mb-6">
        <div className="flex items-center">
          <BarChart3 className="w-5 h-5 text-gray-400 mr-2" />
          <span className="text-sm font-medium text-gray-600">
            Sales Representatives
          </span>
        </div>
        <span className="px-3 py-1 text-sm font-semibold text-blue-600 bg-blue-50 rounded-full">
          {count}
        </span>
      </div>

      {/* Action Button */}

      {count > 0 && (
        <button
          onClick={handleViewReport}
          className="w-full flex items-center justify-center space-x-2 bg-gradient-to-r from-blue-500 cursor-pointer to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white px-6 py-3 rounded-lg font-medium transition-all duration-300 transform group-hover:translate-y-0 translate-y-0"
        >
          <span>View Reports</span>
          <ArrowRight className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1" />
        </button>
      )}

      {/* Hover Effect Overlay */}
      {/* <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-blue-500/0 to-blue-600/0 opacity-0 group-hover:opacity-5 transition-opacity duration-300" /> */}
    </div>
  );
};

export default ManagerCard;
