import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import accessURL from "../../components/utils/access";
import HeaderText from "../components/headerText/HeaderText";
import DescReport from "../components/reports/DescReport";
import Sort from "../components/personnel/sort/Sort";
import LoadingSpinner from "../components/reports/LoadingSpinner";
import EmptyState from "../components/reports/EmptyState";
import Pagination from "../components/reports/Pagination";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import ManagerCard from "../components/reports/ManagerCard/ManagerCard";
import FilterSection from "../components/reports/ManagerCard/FilterSection";
import { FiltersInterface } from "../../salesRep/components/schedule/types/types";

interface PaginationInfo {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
}

interface Managers {
  [key: string]: number;
}

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [managers, setManagers] = useState<Managers>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(10);
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";
  const [filters, setFilters] = useState<FiltersInterface>({});

  useEffect(() => {
    fetchMetrics();
  }, [currentPage, filters]);

  const fetchMetrics = async () => {
    try {
      const params: Record<string, string> = {};
      // Only add defined and non-empty string values to params
      if (filters.startDate) params.start_date = filters.startDate;
      if (filters.endDate) params.end_date = filters.endDate;
      if (filters.search) params.name = filters.search;
      if (filters.date !== undefined) params.date = String(filters.date);
      if (filters.name !== undefined) params.contentName = String(filters.name);

      const queryParams = new URLSearchParams(params);
      const response = await accessURL.get<{
        data: { managers: Managers; pagination: PaginationInfo };
      }>(
        `metrics/getManagersAndAssignedSalesRep?page=${currentPage}&per_page=${perPage}${
          queryParams.toString() ? `&${queryParams.toString()}` : ""
        }`
      );

      setManagers(response?.data.data.managers);
      setTotalPages(response.data.data.pagination.last_page);
    } catch (error) {
      console.error("Error fetching sales reps:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingSpinner />;

  const handleFilterChange = (newFilters: FiltersInterface) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <section className="p-4 md:p-6 lg:p-8 xl:p-14">
      <section className="flex flex-col lg:flex-row justify-between items-center">
        <HeaderText text="Reports" />
      </section>
      <DescReport />
      <div className="flex flex-col h-[calc(100vh-100px)] mx-4 my-10">
        <div className="flex justify-end py-10">
          <FilterSection onFilterChange={handleFilterChange} />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {Object.entries(managers).length === 0 ? (
            <EmptyState />
          ) : (
            Object.entries(managers).map(([name, count]) => (
              <ManagerCard key={name} name={name} count={count} email={email} />
            ))
          )}
        </div>

        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </section>
  );
};

export default Reports;
