import React from "react";
import { FaUser } from "react-icons/fa";

interface DetProps {
  name?: string;
}

const Det: React.FC<DetProps> = ({ name }) => {
  return (
    <div className="w-full p-6 md:p-8">
      <div className="relative overflow-hidden bg-gradient-to-br from-white to-white rounded-2xl shadow-lg">
        {/* Decorative Elements */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-indigo-500" />
        <div className="absolute -right-8 -top-8 w-24 h-24 bg-blue-100 rounded-full opacity-50" />
        <div className="absolute -left-8 -bottom-8 w-32 h-32 bg-indigo-100 rounded-full opacity-50" />

        {/* Content Container */}
        <div className="relative px-6 py-8 md:px-8 md:py-10">
          <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-8">
            {/* Icon Container */}
            <div className="relative">
              <div className="absolute inset-0 bg-blue-200 rounded-full blur-lg opacity-50 transform scale-110" />
              <div className="relative bg-gradient-to-br from-blue-500 to-indigo-500 p-4 rounded-full">
                <FaUser className="w-8 h-8 md:w-10 md:h-10 text-white" />
              </div>
            </div>

            {/* Name Container */}
            <div className="text-center md:text-left">
              <div className="relative">
                <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-indigo-600">
                  {name || "Loading..."}
                </h2>
                <div className="mt-1 h-1 w-12 md:w-16 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full mx-auto md:mx-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Det;
