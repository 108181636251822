import React from "react";
import Det from "../components/reports/single_reports/salesRepDet/Det";
import { useParams } from "react-router-dom";
import AllRep from "../components/allReports/AllRep";

const AllReports: React.FC = () => {
  const { salesRepID } = useParams();
  return (
    <section>
      <Det />
      <AllRep id={Number(salesRepID)} />
    </section>
  );
};

export default AllReports;
