import React from "react";
import RepCard from "./RepCard";

interface SalesRep {
  salesRepID: number;
  salesRepName: string;
  totalReports: number;
}

interface RepsProps {
  reps?: SalesRep[];
  name?: string;
}

const Reps: React.FC<RepsProps> = ({ name, reps = [] }) => {
  // Get 4 random sales reps if there are more than 4
  const displayReps =
    reps.length > 4 ? reps.sort(() => 0.5 - Math.random()).slice(0, 4) : reps;

  return (
    <div className="relative w-full">
      {/* Gradient Fade Effect */}
      <div className="absolute right-0 top-0 bottom-0 w-20 bg-gradient-to-l from-gray-100 to-transparent z-10 pointer-events-none hidden lg:block" />
      <div className="absolute left-0 top-0 bottom-0 w-20 bg-gradient-to-r from-gray-100 to-transparent z-10 pointer-events-none hidden lg:block" />

      {/* Scrollable Container */}
      <div className="w-full overflow-x-auto scrollbar-thin pb-4">
        <div
          className="
          grid grid-cols-1 gap-6 sm:gap-8 w-full
          sm:grid-cols-2 
          lg:grid-cols-none lg:flex lg:space-x-6
          min-w-full
        "
        >
          {displayReps.map((rep) => (
            <div key={rep.salesRepID} className="lg:w-[350px] lg:flex-shrink-0">
              <RepCard name={name} rep={rep} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reps;
