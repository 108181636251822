import React from "react";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import YearSelector from "../customs/YearSelector";
import Analytics from "./Analytics";

const VisitAnalytics: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <section>
      {/* <h1 className="font-bold text-xl text-center py-3">Visit Analytics</h1> */}
      <Analytics data={data} isLoading={isLoading} />
    </section>
  );
};

export default VisitAnalytics;
