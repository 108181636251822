import React, { useState, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import accessURL from "../../../components/utils/access";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface ResetProps {
  token: string;
}

const Reset: React.FC<ResetProps> = ({ token }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordConditions, setPasswordConditions] = useState({
    isUpperCase: false,
    isLowerCase: false,
    isNumber: false,
    isSpecialChar: false,
    isLengthValid: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setPasswordConditions({
      isUpperCase: /[A-Z]/.test(password),
      isLowerCase: /[a-z]/.test(password),
      isNumber: /\d/.test(password),
      isSpecialChar: /[@$!%*?&]/.test(password),
      isLengthValid: password.length >= 8,
    });
  }, [password]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }
    const { isUpperCase, isLowerCase, isNumber, isSpecialChar, isLengthValid } =
      passwordConditions;
    if (
      !isUpperCase ||
      !isLowerCase ||
      !isNumber ||
      !isSpecialChar ||
      !isLengthValid
    ) {
      setMessage("Password does not meet the required conditions.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        // "https://www.syncai.ltd/api/syncai-crm/reset-password",
        "http://localhost/syncai-crm/reset-password",
        { password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        setMessage("Password reset successfully!");
        setTimeout(() => {
          navigate("/login");
        }, 4000);
      } else {
        setMessage("token expired");
      }
    } catch (error) {
      setMessage("An error occurred while resetting the password.");
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="max-w-md mx-auto p-8 rounded-lg">
      <h1 className="text-2xl font-bold text-blue-700 mb-6">
        Reset Your Password
      </h1>
      {message && (
        <div className="mb-4 p-4 text-white bg-blue-500 rounded-lg">
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* New Password */}
        <div className="relative">
          <label className="block text-blue-700 font-semibold mb-2">
            New Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 pr-10 border border-blue-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              placeholder="Enter new password"
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center justify-center text-blue-500"
              disabled={isLoading}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        {/* Confirm Password */}
        <div className="relative">
          <label className="block text-blue-700 font-semibold mb-2">
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-2 pr-10 border border-blue-300 rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
              placeholder="Confirm new password"
              disabled={isLoading}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-3 flex items-center justify-center text-blue-500"
              disabled={isLoading}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>

        {/* Password Conditions */}
        <div className="text-sm text-blue-600">
          <p
            className={`${
              passwordConditions.isUpperCase ? "text-green-500" : "text-red-500"
            } flex items-center`}
          >
            • Must include an uppercase letter
          </p>
          <p
            className={`${
              passwordConditions.isLowerCase ? "text-green-500" : "text-red-500"
            } flex items-center`}
          >
            • Must include a lowercase letter
          </p>
          <p
            className={`${
              passwordConditions.isNumber ? "text-green-500" : "text-red-500"
            } flex items-center`}
          >
            • Must include a number
          </p>
          <p
            className={`${
              passwordConditions.isSpecialChar
                ? "text-green-500"
                : "text-red-500"
            } flex items-center`}
          >
            • Must include a special character
          </p>
          <p
            className={`${
              passwordConditions.isLengthValid
                ? "text-green-500"
                : "text-red-500"
            } flex items-center`}
          >
            • Must be at least 8 characters
          </p>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
              Resetting Password...
            </div>
          ) : (
            "Reset Password"
          )}
        </button>
      </form>
    </div>
  );
};

export default Reset;
