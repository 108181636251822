import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { modalAnimation } from "../../../../../../../animations/Animations";
import Managers from "./Managers";

interface ManagerData {
  ID: number;
  companyID: string;
  name: string;
  role: string;
  email: string;
  cpa: number | null;
  salesAchieved: number | null;
  cpaGrowthPeriod: number | null;
  salesGrowthPeriod: number | null;
}

interface AssignManagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  salesRepName: string;
  onAssign: () => void;
  onSelect: (manager: ManagerData | null) => void;
  selectedManager: ManagerData | null;
  isAssigning: boolean;
  assignmentSuccess: boolean;
  assignedManager: string;
}

const ReAssignMangerModal: React.FC<AssignManagerModalProps> = ({
  isOpen,
  onClose,
  salesRepName,
  onAssign,
  onSelect,
  selectedManager,
  isAssigning,
  assignedManager,
}) => {
  if (!isOpen) return null;

  const handleAssign = () => {
    if (selectedManager) {
      onAssign();
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={modalAnimation}
      >
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full lg:max-w-xl xl:max-w-3xl">
          <h2 className="text-2xl font-bold mb-6 md:text-3xl ">
            Assign manager
          </h2>
          <p className="mb-4 text-lg font-medium">
            You are about to assign a manager to a Sales Representative:{" "}
            <span className="text-blue-600 font-bold capitalize py-2">
              {salesRepName}
            </span>
          </p>

          <section className="py-4">
            <Managers
              onSelect={onSelect}
              assignedManager={assignedManager}
            />
          </section>

          <p className="mb-8 text-base text-lg text-gray-700 font-medium">
            Please ensure you have selected the correct manager before
            proceeding.
          </p>
          <div className="flex justify-end space-x-6">
            <button
              onClick={onClose}
              className="px-6 py-3 bg-gray-300 rounded-md hover:bg-gray-400 transition duration-300 text-lg font-semibold"
            >
              Cancel
            </button>
            <button
              onClick={handleAssign}
              disabled={!selectedManager || isAssigning}
              className={`px-6 py-3 text-white rounded-md transition duration-300 text-lg font-semibold ${
                selectedManager && !isAssigning
                  ? "bg-blue-500 hover:bg-blue-600"
                  : "bg-blue-300 cursor-not-allowed"
              }`}
            >
              {isAssigning ? (
                <span className="flex items-center">
                  <svg
                    className="animate-spin h-5 w-5 mr-3 text-white"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                </span>
              ) : (
                "Assign"
              )}
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ReAssignMangerModal;
