import React, { useState } from "react";
import { FaCalendar, FaTimesCircle } from "react-icons/fa";
import { useDateFilter } from "../../../../contexts/dashboard/DateFilterContext";

const DateRangePicker: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dateFilter = useDateFilter();

  // Add a null check
  if (!dateFilter) {
    return null; // or handle the error appropriately
  }

  const { startDate, endDate, setStartDate, setEndDate } = dateFilter;

  const handleDateChange = (type: "start" | "end", date: string) => {
    if (type === "start") setStartDate(date);
    else setEndDate(date);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center gap-2 bg-blue-900 text-white px-4 py-2 rounded-lg hover:bg-blue-100 transition-colors"
        >
          <FaCalendar />
          {startDate && endDate
            ? `${startDate} - ${endDate}`
            : "Select Date Range"}
        </button>

        {(startDate || endDate) && (
          <button
            onClick={handleReset}
            className="text-red-500 hover:text-red-600"
            title="Reset Date Range"
          >
            <FaTimesCircle />
          </button>
        )}
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-2 bg-white border rounded-lg shadow-lg p-4">
          <div className="flex gap-4 mb-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => handleDateChange("start", e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => handleDateChange("end", e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
              />
            </div>
          </div>
          <button
            onClick={() => setIsOpen(false)}
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
