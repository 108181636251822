import React from "react";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../../../../store";
import AllReports from "./AllReports";

const Reports: React.FC<{ salesRepID: number }> = ({ salesRepID }) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";

  return (
    <section>
      <div className="my-4 p-3 flex justify-center lg:justify-start">
        <Link
          to={`/admin/${email}/report/reports/all/${salesRepID}`}
          className="inline-flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition-all duration-300"
        >
          See All Reports
          <HiOutlineArrowRight className="ml-2 text-lg" />
        </Link>
      </div>
      <AllReports salesRepID={salesRepID} />
    </section>
  );
};

export default Reports;
