import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import ReportCard from "../../../../../../salesRep/components/reports/reports/ReportCard";
import accessURL from "../../../../../../components/utils/access";
import { useDateFilter } from "../../../../../../contexts/dashboard/DateFilterContext";
import { Reports } from "../../../../../../salesRep/components/schedule/types/types";

export interface ReportsResponse {
  success: boolean;
  message: string;
  data: {
    salesRep: string;
    reports: Reports[];
    pagination: {
      currentPage: number;
      totalPages: number;
      totalRecords: number;
      limit: number;
    };
  };
}

const fetchReports = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  id?: number
): Promise<ReportsResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("startDate", startDate);
  if (endDate) params.append("endDate", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(
    `reports/singleReport?id=${id}&${params.toString()}`
  );
  return {
    success: res.data.success,
    message: res.data.message,
    data: res.data.data,
  };
};

const AllReports: React.FC<{ salesRepID: number }> = ({ salesRepID }) => {
  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading, error } = useQuery({
    queryKey: ["singleReports", startDate, endDate, selectedYear, salesRepID],
    queryFn: () => fetchReports(startDate, endDate, selectedYear, salesRepID),
  });

  const randomReports = useMemo(() => {
    const reports = data?.data?.reports ?? [];
    if (reports.length <= 5) return reports;

    const shuffled = [...reports].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 5);
  }, [data?.data?.reports]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center my-10">
        <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center my-10 text-red-500">
        <p>Failed to fetch reports. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className="w-full px-4 md:px-6 lg:px-8">
      {randomReports.length === 0 ? (
        <div className="flex justify-center items-center my-10 text-gray-500">
          <p>No reports available at the moment. 📄</p>
        </div>
      ) : (
        <div className="relative">
          <div className="overflow-x-auto pb-4 scrollbar-thin hide-scrollbar">
            <div className="flex gap-4 md:gap-6 min-w-min">
              {randomReports.map((report) => (
                <div key={report.id} className="w-[300px] flex-shrink-0">
                  <ReportCard report={report} access="admin" />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllReports;
