import React, { useState } from "react";
import accessURL from "../../../../../../components/utils/access";
import { ManagerData } from "../../../../../../salesRep/components/schedule/types/types";
import ReAssignMangerModal from "./reassign/ReAssignMangerModal";

interface Props {
  row: {
    name: string;
    email: string;
    ID: number;
    companyID: string;
    role: string;
    assigned: number;
    assignedManager: string | null;
  };
}

const Reassign: React.FC<Props> = ({ row }) => {
  const [selectedManager, setSelectedManager] = useState<ManagerData | null>(
    null
  );
  const [showAssignModal, setShowAssignModal] = useState(false);


  const [isAssigning, setIsAssigning] = useState(false);
  const [assignmentSuccess, setAssignmentSuccess] = useState(false);

  const handleAssignManager = () => {
    setShowAssignModal(true);
  };

  const handleConfirmAssign = async () => {
    if (selectedManager) {
      setIsAssigning(true);
      const response = await accessURL.post("/managers/assignSalesRep", {
        managerID: selectedManager.ID,
        salesRepID: row.ID,
      });

      if (response.data && response.data.status === "success") {
        setAssignmentSuccess(true);
        setTimeout(() => {
          setAssignmentSuccess(false);
          setShowAssignModal(false);
          window.location.reload();
        }, 2000);
      }

      setIsAssigning(false);
    }
  };

  return (
    <div className="relative">
      <div className="flex space-x-4">
        <button
          disabled={row.assigned !== 1}
          onClick={handleAssignManager}
          className={`bg-black text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform text-sm ${
            row.assigned !== 1
              ? "opacity-50 cursor-not-allowed hover:bg-black hover:scale-100"
              : "hover:bg-blue-600 hover:scale-105"
          }`}
        >
          Re-Assign
        </button>
      </div>
      <ReAssignMangerModal
        isOpen={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        salesRepName={row.name}
        onAssign={handleConfirmAssign}
        onSelect={setSelectedManager}
        selectedManager={selectedManager}
        isAssigning={isAssigning}
        assignmentSuccess={assignmentSuccess}
        assignedManager={row.assignedManager || ""}
      />
    </div>
  );
};

export default Reassign;
