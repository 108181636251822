import React from "react";
import LoginPage from "../pages/LoginPage";
import Pricing from "../pages/Pricing";
import Info from "../components/pricing/info/Info";
import RedirectToOnboarding from "../../components/redirect_to_login/RedirectToOnboarding";
import ForgotPage from "../pages/ForgotPage";

const OnboardingRoutes = [
  {
    path: "/",
    element: <RedirectToOnboarding />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <LoginPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/pricing/info",
    element: <Info />,
  },
];
export default OnboardingRoutes;
