import React from "react";
import { useParams } from "react-router-dom";
import Det from "../components/reports/single_reports/salesRepDet/Det";
import ManagerMetrics from "../components/reports/ManagerCard/ManagerMetrics";
import { useDateFilter } from "../../contexts/dashboard/DateFilterContext";
import { useQuery } from "@tanstack/react-query";
import accessURL from "../../components/utils/access";
import { ApiResponse } from "../../salesRep/components/schedule/types/types";

const metricsDashboardManager = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  name?: string
): Promise<ApiResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(
    `metrics/getMetrics?name=${name}&role=manager&${params.toString()}`
  );
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
  };
};

const SingleReports: React.FC = () => {
  const { name } = useParams();

  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading } = useQuery({
    queryKey: ["metrics", startDate, endDate, selectedYear, name],
    queryFn: () =>
      metricsDashboardManager(startDate, endDate, selectedYear, name),
  });

  return (
    <section className="py-10 px-8">
      <Det name={name} />
      <ManagerMetrics name={name} data={data} isLoading={isLoading} />
    </section>
  );
};

export default SingleReports;
