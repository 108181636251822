import React, { useState } from "react";
import accessURL from "../../../../../../components/utils/access";
import AssignManagerModal from "./AssignManagerModal";
import { ManagerData } from "../../../../../../salesRep/components/schedule/types/types";

interface ActionProps {
  id: number;
  name: string;
  assigned: number;
}

const Action: React.FC<ActionProps> = ({ id, name, assigned }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState<ManagerData | null>(
    null
  );
  const [isAssigning, setIsAssigning] = useState(false);
  const [assignmentSuccess, setAssignmentSuccess] = useState(false);

  const handleAssignManager = () => {
    setShowAssignModal(true);
  };

  const handleConfirmAssign = async () => {
    if (selectedManager) {
      setIsAssigning(true);
      const response = await accessURL.post("/managers/assignSalesRep", {
        managerID: selectedManager.ID,
        salesRepID: id,
      });

      if (response.data && response.data.status === "success") {
        setAssignmentSuccess(true);
        setTimeout(() => {
          setAssignmentSuccess(false);
          setShowAssignModal(false);
          window.location.reload();
        }, 2000);
      }

      setIsAssigning(false);
    }
  };

  const handleDeleteSalesRep = async () => {
    const response = await accessURL.post("/salesRep/delete", {
      id: id,
    });

    if (
      response.data &&
      response.data.status === "success" &&
      response.data.code === 200
    ) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <div className="relative">
      <div className="flex space-x-4">
        {assigned === 1 ? (
          <button
            disabled
            className="bg-gray-400 text-white cursor-not-allowed font-semibold py-2 px-6 rounded-md transition duration-300 text-sm"
          >
            Assigned
          </button>
        ) : (
          <button
            onClick={handleAssignManager}
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
          >
            Assign
          </button>
        )}

        <button
          onClick={() => setShowConfirmModal(true)}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
        >
          Delete
        </button>
      </div>

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
            <p className="mb-4">
              This action is irreversible. Do you understand?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteSalesRep}
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {(showSuccessModal || assignmentSuccess) && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg z-50">
          {showSuccessModal
            ? "Deleted successfully"
            : "Manager assigned successfully"}
        </div>
      )}

      {/* Assign Manager Modal */}
      <AssignManagerModal
        isOpen={showAssignModal}
        onClose={() => setShowAssignModal(false)}
        salesRepName={name}
        onAssign={handleConfirmAssign}
        onSelect={setSelectedManager}
        selectedManager={selectedManager}
        isAssigning={isAssigning}
        assignmentSuccess={assignmentSuccess}
      />
    </div>
  );
};

export default Action;
