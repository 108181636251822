import React, { useState } from "react";
import { LuBook } from "react-icons/lu";
import FollowupModal from "./Followup";

// import { toast } from "react-toastify";
import { Reports } from "../../../../../../../salesRep/components/schedule/types/types";
// import accessURL from "../../../../../../../components/utils/access";
import Modal from "../../../../../../../salesRep/components/reports/reports/Modal";

interface ReportCardProps {
  report: Reports;
}

const ReportCard: React.FC<ReportCardProps> = ({ report }) => {
  const [modalOpen, setModalOpen] = useState(false);
  // const [isFollowUpModalOpen, setIsFollowUpModalOpen] = useState(false);
  // const [followUpPlan, setFollowUpPlan] = useState("");
  // const [isFollowUpSuccess, setIsFollowUpSuccess] = useState(false);
  // const [isLoading, setLoading] = useState(false);

  const categoryColor = () => {
    switch (report.customerCategory) {
      case "A":
        return "text-green-700";
      case "B":
        return "text-yellow-700";
      case "C":
        return "text-red-700";
      default:
        return "text-gray-700";
    }
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // const handleAddFollowUp = () => {
  //   setIsFollowUpModalOpen(true);
  // };

  // const handleSubmitFollowUp = async (plan: string) => {
  //   try {
  //     setLoading(true);
  //     const response = await accessURL.post(
  //       `reports/followUpPlan?id=${report.id}`,
  //       {
  //         followUpPlan: plan,
  //       }
  //     );

  //     if (response.status === 200) {
  //       toast.success("Follow-up plan updated successfully!");
  //       setFollowUpPlan(plan);
  //       setIsFollowUpSuccess(true);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     } else {
  //       console.error("Error saving follow-up plan:", response.status);
  //       toast.error("Error saving follow-up plan. Please try again later.");
  //     }
  //   } catch (error) {
  //     console.error("Error saving follow-up plan:", error);
  //     toast.error("Error saving follow-up plan. Please try again later.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const handleCloseFollowUpModal = () => {
  //   setIsFollowUpModalOpen(false);
  //   setIsFollowUpSuccess(false);
  // };

  return (
    <div className="bg-white shadow-xl rounded-md p-4">
      {/* Top Section */}
      <div className="flex justify-between items-center mb-2 py-2">
        <LuBook
          className="text-xl cursor-pointer text-blue-600 md:text-4xl"
          onClick={openModal}
        />
        {/* <button
          onClick={handleAddFollowUp}
          className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center transition duration-300 ease-in-out"
        >
          Add Follow-Up
        </button> */}
      </div>
      <h3 className="text-xl capitalize font-bold text-black mb-2 truncate py-3">
        {report.customer}
      </h3>
      <div className="flex flex-col md:flex-row items-center justify-between">
        {report.followUpPlan && report.followUpPlan !== "" && (
          <div
            className="group relative max-w-[70%]"
            title="Click on view to see follow-up plan"
          >
            <p className="text-purple-600 font-medium truncate">
              Follow up: {report.followUpPlan}
            </p>
          </div>
        )}
        <p className={`text-right text-xl md:text-2xl ${categoryColor()}`}>
          {report.customerCategory}
        </p>
      </div>

      <p className="text-gray-600 mb-2 truncate py-2">
        Details of Visit: {report.objective}
      </p>
      <button
        className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center items-center transition duration-300 ease-in-out w-full"
        onClick={openModal}
      >
        View
      </button>

      {/* View Report Modal */}
      <Modal isOpen={modalOpen} onClose={closeModal} report={report} />

      {/* Follow-Up Modal */}
      {/* <FollowupModal
        isOpen={isFollowUpModalOpen}
        onClose={handleCloseFollowUpModal}
        onSubmit={handleSubmitFollowUp}
        isSuccess={isFollowUpSuccess}
      /> */}
    </div>
  );
};

export default ReportCard;
