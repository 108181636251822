import React, { useState } from "react";
import { IoIosCreate } from "react-icons/io";
import { toast } from "react-toastify";
import accessURL from "../../../components/utils/access";
import CreateScheduleModal from "./ScheduleForm";
import { ScheduleAPIData } from "./types/types";

const CreateSchedule: React.FC<{ anotherDateState: Date | null }> = ({
  anotherDateState,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async (data: ScheduleAPIData) => {
    const response = await accessURL.post("/schedules/create", data);

    if (response.status === 201) {
      toast.success("Schedule created successfully!");
      setTimeout(() => {
        window.location.reload();
        setIsModalOpen(false);
      }, 2000);
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="flex items-center gap-2 bg-blue-900 text-white px-4 py-2 rounded text-xl"
      >
        <IoIosCreate /> Create Schedule
      </button>

      <CreateScheduleModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleSubmit}
        anotherDateState={anotherDateState}
      />
    </div>
  );
};

export default CreateSchedule;
