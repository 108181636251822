import React from "react";
import Header from "../header/Header";
import ForgetPassword from "../components/forget_password/ForgetPassword";

const ForgotPage: React.FC = () => {
  return (
    <section>
      <Header />
      <ForgetPassword />
    </section>
  );
};

export default ForgotPage;
