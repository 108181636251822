import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { Props } from "../../../../salesRep/components/schedule/types/types";

const Analytics: React.FC<Props> = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>
      </div>
    );
  }

  if (!data?.data?.visitAnalytics?.monthly_data) {
    return <div>No data available</div>;
  }

  const currentMonth = new Date().getMonth(); // Get the current month index (0-based)
  const { monthly_data, total_reports } = data.data.visitAnalytics;

  // Map data to include abbreviated months and both percentage and total
  const formattedData = monthly_data.map((item, index) => ({
    ...item,
    month: item.month.slice(0, 3), // Convert to abbreviations (e.g., "Jan", "Feb")
    percentage: item.percentage,
    total: item.total,
    index,
  }));

  return (
    <div className="py-3 space-y-4">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={formattedData} barSize={25}>
          <XAxis
            dataKey="month"
            tick={{ fontSize: 12 }}
            tickLine={false}
            axisLine={{ stroke: "#ccc", strokeWidth: 1 }}
          />
          <YAxis
            type="number"
            domain={[0, 100]}
            tick={{ fontSize: 12 }}
            tickLine={false}
            axisLine={{ stroke: "#ccc", strokeWidth: 1 }}
            tickFormatter={(value) => `${value}%`} // Add % to Y-axis values
          />
          <Tooltip
            content={({ payload, label }) => {
              if (!payload || payload.length === 0) return null;
              const { percentage, total } = payload[0].payload;
              return (
                <div className="bg-white p-3 rounded shadow-md text-sm text-gray-700">
                  <div>
                    <strong>{label}</strong>
                  </div>
                  <div>Percentage: {percentage}%</div>
                  <div>Total: {total}</div>
                </div>
              );
            }}
          />
          <Bar dataKey="percentage">
            {formattedData.map((entry) => (
              <Cell
                key={`cell-${entry.index}`}
                fill={entry.index === currentMonth ? "#7e22ce" : "#a855f7"}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {/* Total Reports Section */}
      {/* <div className="text-center text-lg font-medium text-gray-700">
        Overall Total Reports: {total_reports}
      </div> */}
    </div>
  );
};

export default Analytics;
