import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";

interface PasswordValidation {
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  isMinLength: boolean;
}

const SalesRepSecurityInfo: React.FC<{
  formData: any;
  errors: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ formData, errors, handleChange }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidation>({
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isMinLength: false,
    });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password: string) => {
    setPasswordValidation({
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isMinLength: password.length >= 8,
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    validatePassword(e.target.value);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2 relative">
          <FaLock className="text-lg text-gray-600" />
          <input
            type={showPassword ? "text" : "password"}
            name="password"
            placeholder="SalesRep Password"
            value={formData.password}
            onChange={handlePasswordChange}
            className="w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 pr-10"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 focus:outline-none"
          >
            {showPassword ? (
              <FaEyeSlash className="text-gray-500 hover:text-gray-700" />
            ) : (
              <FaEye className="text-gray-500 hover:text-gray-700" />
            )}
          </button>
        </div>

        {errors.password && (
          <p className="text-red-500 text-sm">{errors.password}</p>
        )}

        <div className="text-sm space-y-1 mt-2">
          <p className="font-medium text-gray-700">Password must contain:</p>
          <ul className="space-y-1">
            <li
              className={`flex items-center space-x-2 ${
                passwordValidation.hasUpperCase
                  ? "text-green-600"
                  : "text-gray-500"
              }`}
            >
              <span>{passwordValidation.hasUpperCase ? "✓" : "○"}</span>
              <span>Uppercase letter</span>
            </li>
            <li
              className={`flex items-center space-x-2 ${
                passwordValidation.hasLowerCase
                  ? "text-green-600"
                  : "text-gray-500"
              }`}
            >
              <span>{passwordValidation.hasLowerCase ? "✓" : "○"}</span>
              <span>Lowercase letter</span>
            </li>
            <li
              className={`flex items-center space-x-2 ${
                passwordValidation.hasNumber
                  ? "text-green-600"
                  : "text-gray-500"
              }`}
            >
              <span>{passwordValidation.hasNumber ? "✓" : "○"}</span>
              <span>Number</span>
            </li>
            <li
              className={`flex items-center space-x-2 ${
                passwordValidation.hasSpecialChar
                  ? "text-green-600"
                  : "text-gray-500"
              }`}
            >
              <span>{passwordValidation.hasSpecialChar ? "✓" : "○"}</span>
              <span>Special character</span>
            </li>
            <li
              className={`flex items-center space-x-2 ${
                passwordValidation.isMinLength
                  ? "text-green-600"
                  : "text-gray-500"
              }`}
            >
              <span>{passwordValidation.isMinLength ? "✓" : "○"}</span>
              <span>Minimum 8 characters</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SalesRepSecurityInfo;
