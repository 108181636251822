import React, { useState, useEffect, useRef } from "react";
import { ArrowUpDown, Search, RotateCcw } from "lucide-react";

interface Filters {
  startDate?: string;
  endDate?: string;
  date?: boolean;
  search?: string;
  name?: boolean;
  assigned?:boolean,
  sortBy?: string;
  sortOrder?: "asc" | "desc";
}

interface FilterSectionProps {
  onFilterChange: (filters: Filters) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({ onFilterChange }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<Filters>({
    date: false,
    name: false,
    assigned:false
  });

  const sortRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => setShowOptions(!showOptions);

  const handleCheckboxChange = (option: keyof Filters) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [option]: !selectedOptions[option],
    };
    setSelectedOptions(newSelectedOptions);
  };

  const handleApplyFilter = () => {
    const activeSortOption = Object.entries(selectedOptions).find(
      ([key, value]) => value === true
    )?.[0];

    const filters: Filters = {
      ...selectedOptions,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      search: search || undefined,
      sortBy: activeSortOption,
      sortOrder: "asc",
    };

    onFilterChange(filters);
  };

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setSearch("");
    setSelectedOptions({
      date: false,
      name: false,
      assigned:false
    });
    onFilterChange({});
  };

  return (
    <div className="bg-white shadow-sm rounded-lg p-6">
      {/* <div className="relative flex justify-end" ref={sortRef}>
        <button
          onClick={toggleOptions}
          className="flex items-center space-x-2 px-4 py-2 bg-blue-50 hover:bg-blue-100 rounded-md transition-colors"
        >
          <span className="font-medium text-blue-600">Sort</span>
          <ArrowUpDown className="h-4 w-4 text-blue-600" />
        </button>

        {showOptions && (
          <div className="absolute right-0 top-full mt-1 w-48 bg-white border border-gray-100 rounded-md shadow-lg z-10">
            <div className="py-1">
              {(Object.keys(selectedOptions) as Array<keyof Filters>).map(
                (option) => (
                  <label
                    key={option}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      checked={!!selectedOptions[option]}
                      onChange={() => handleCheckboxChange(option)}
                      className="mr-2 accent-blue-600"
                    />
                    {option.replace(/([A-Z])/g, " $1").trim()}
                  </label>
                )
              )}
            </div>
          </div>
        )}
      </div> */}

      <div className="flex flex-col lg:flex-row lg:items-end lg:gap-6 mt-4">
        <div className="flex flex-col lg:flex-row lg:flex-1 gap-4">
          <div className="flex-1">
            <label className="block mb-1.5 text-sm font-medium text-gray-700">
              Start Date
            </label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full px-3 py-2 bg-blue-50 border border-blue-100 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            />
          </div>

          <div className="flex-1">
            <label className="block mb-1.5 text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full px-3 py-2 bg-blue-50 border border-blue-100 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            />
          </div>
        </div>

        <div className="flex-1 mt-4 lg:mt-0">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Enter Sales Representative Name"
            className="w-full px-3 py-2 bg-blue-50 border border-blue-100 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
          />
        </div>

        <div className="flex gap-2 mt-4 lg:mt-0">
          <button
            onClick={handleApplyFilter}
            className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors"
          >
            <Search className="h-4 w-4" />
            Apply Filter
          </button>
          <button
            onClick={handleReset}
            className="flex items-center gap-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-md transition-colors"
          >
            <RotateCcw className="h-4 w-4" />
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
