import React, { useState, useEffect } from "react";
import YearSelector from "../../dashboard/customs/YearSelector";
import DateRangePicker from "../../dashboard/customs/DateRangePicker";
import accessURL from "../../../../components/utils/access";
import { useDateFilter } from "../../../../contexts/dashboard/DateFilterContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { useQuery } from "@tanstack/react-query";
import RepCard from "../single_reports/salesrepinfo/RepCard";
import { ChevronLeft, ChevronRight, Search } from "lucide-react";

interface Meta {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface SalesRep {
  salesRepID: number;
  salesRepName: string;
  totalReports: number;
}

interface SalesRepResponse {
  status: string;
  code: number;
  data: SalesRep[];
  meta: Meta;
}

const fetchReps = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  name?: string,
  search?: string,
  page: number = 1,
  limit: number = 10
): Promise<SalesRepResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());
  if (search) params.append("search", search);

  params.append("page", page.toString());
  params.append("limit", limit.toString());

  const res = await accessURL(
    `metrics/getManagersSalesRep?name=${name}&${params.toString()}`
  );

  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
    meta: res.data.meta,
  };
};

const All: React.FC<{ manager?: string }> = ({ manager }) => {
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  // Debounce effect
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchQuery);
      setPage(1);
    }, 700); // 500ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const { data, isLoading } = useQuery({
    queryKey: [
      "repsandreports",
      startDate,
      endDate,
      selectedYear,
      manager,
      debouncedSearch,
      page,
      limit,
    ],
    queryFn: () =>
      fetchReps(
        startDate,
        endDate,
        selectedYear,
        manager,
        debouncedSearch,
        page,
        limit
      ),
  });

  // Pagination handlers
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < (data?.meta.totalPages ?? 1)) {
      setPage(page + 1);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  return (
    <section className="w-full p-4 bg-white rounded-lg shadow-md my-10">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="w-full sm:w-auto">
          <DateRangePicker />
        </div>
        <div className="w-full sm:w-auto">
          <YearSelector />
        </div>
        <div className="w-full sm:w-auto relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Enter sales rep name"
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
        </div>
      </div>

      {/* Conditional Rendering */}
      {isLoading ? (
        <div className="text-center text-gray-500">Loading...</div>
      ) : data?.data.length === 0 ? (
        <div className="text-center text-gray-600 text-lg">
          No sales representatives found 📊
        </div>
      ) : (
        <>
          {/* Grid Layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {data?.data.map((rep) => (
              <div key={rep.salesRepID}>
                <RepCard name={manager} rep={rep} />
              </div>
            ))}
          </div>

          {/* Pagination */}
          {(data?.meta.totalPages ?? 1) > 1 && (
            <div className="flex justify-center items-center mt-6 space-x-4">
              <button
                onClick={handlePreviousPage}
                disabled={page === 1}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                <ChevronLeft className="mr-2" /> Previous
              </button>
              <span className="text-gray-600">
                Page {page} of {data?.meta.totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={page === (data?.meta.totalPages ?? 1)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed flex items-center"
              >
                Next <ChevronRight className="ml-2" />
              </button>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default All;
