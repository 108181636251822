import React, { useEffect, useState } from "react";
import DataTable, {
  TableColumn,
  TableStyles,
} from "react-data-table-component";
import accessURL from "../../../../../components/utils/access";
import Btn from "./btn/Btn";
import FilterSection from "./FilterSection";

interface ProductData {
  id: number;
  name: string;
  plan: number;
  achieved: number | null;
  pending: number | null;
  most_used_content: string | null;
  least_used_content: string | null;
  companyName: string;

  companyID: string;
  date_created: string;
  date_updated: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalProducts: number;
  limit: number;
}

interface Filters {
  startDate?: string;
  endDate?: string;
  date?: boolean;
  search?: string;
  name?: boolean;
}

const Products: React.FC = () => {
  const [data, setData] = useState<ProductData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  const columns: TableColumn<ProductData>[] = [
    {
      name: "S/N",
      selector: (row) => row.id,
      format: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "200px",
    },

    {
      name: "Most used content",
      selector: (row) => row.most_used_content || "N/A",
      wrap: true,
      width: "300px",
    },
    {
      name: "Least used content",
      selector: (row) => row.least_used_content || "N/A",
      wrap: true,
      width: "300px",
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = new Date(row.date_created);
        return new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(date);
      },
      wrap: true,
      width: "200px",
    },
    {
      name: "Actions",
      cell: (row: ProductData) => <Btn id={row.id} data={row} />,
      width: "280px",
    },
  ];

  const fetchProducts = async (page: number, pageSize: number) => {
    setLoading(true);

    const params: Record<string, string> = {};
    
    // Only add defined and non-empty string values to params
    if (filters.startDate) params.start_date = filters.startDate;
    if (filters.endDate) params.end_date = filters.endDate;
    if (filters.search) params.name = filters.search;
    if (filters.date !== undefined) params.date = String(filters.date);
    if (filters.name !== undefined) params.contentName = String(filters.name);
    
    const queryParams = new URLSearchParams(params);
    
    let url = `products?page=${page}&limit=${pageSize}${queryParams.toString() ? `&${queryParams.toString()}` : ''}`;
    
    const response = await accessURL.get<{
      data: ProductData[];
      pagination: PaginationInfo;
    }>(url);
    
    setData(response.data.data);
    setTotalRows(response.data.pagination.totalProducts);
    setLoading(false);
  };

  useEffect(() => {
    fetchProducts(currentPage, perPage);
  }, [currentPage, perPage, filters]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const customStyles: TableStyles = {
    table: {
      style: {
        backgroundColor: "#f8fafc",
        borderRadius: "0.5rem",
        overflow: "hidden",
      },
    },
    headRow: {
      style: {
        backgroundColor: "#1e40af",
        color: "white",
        fontWeight: "bold",
        textTransform: "uppercase" as const,
      },
    },
    rows: {
      style: {
        minHeight: "60px",
      },
      stripedStyle: {
        backgroundColor: "#f1f5f9",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    pagination: {
      style: {
        backgroundColor: "#f8fafc",
        color: "#1e40af",
        fontWeight: "bold",
      },
      pageButtonsStyle: {
        borderRadius: "0.375rem",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "0 5px",
        cursor: "pointer",
        transition: "0.2s ease-out",
        color: "#1e40af",
        fill: "#1e40af",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",
          color: "#cbd5e1",
          fill: "#cbd5e1",
        },
        "&:hover:not(:disabled)": {
          backgroundColor: "#dbeafe",
        },
        "&:focus": {
          outline: "none",
          backgroundColor: "#bfdbfe",
        },
      },
    },
  };

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="py-6">
        <FilterSection onFilterChange={handleFilterChange} />
      </div>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        paginationPerPage={perPage}
        striped
        highlightOnHover
        responsive
        persistTableHead
        customStyles={customStyles}
        noDataComponent={
          <div className="p-4 text-center text-gray-500">No products found</div>
        }
      />
    </div>
  );
};

export default Products;
