import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import * as XLSX from "xlsx";
import { FileSpreadsheet } from 'lucide-react';

interface ExcelViewerProps {
  url: string;
  onClose: () => void;
}

const ExcelViewer: React.FC<ExcelViewerProps> = ({ url, onClose }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<string[][] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadExcel = async () => {
      try {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert worksheet to 2D array and cast to `string[][]`
        const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, {
          header: 1,
        }) as string[][];

        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error loading Excel file:", error);
        setError("Failed to load Document. Please try downloading instead.");
        setIsLoading(false);
      }
    };

    loadExcel();
  }, [url]);

  const handleDownload = () => {
    window.open(url, "_blank");
  };

  return (
    <motion.div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[9999]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <motion.div
        className="bg-white p-6 rounded-lg shadow-xl relative max-w-4xl w-full mx-4 z-[60]"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={handleDownload}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            Download Excel
          </button>
          <button
            onClick={onClose}
            className="bg-gray-200 hover:bg-gray-300 rounded-full p-2"
          >
            Close
          </button>
        </div>

        {isLoading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}

        {error && (
          <div className="flex flex-col items-center justify-center p-8 space-y-4">
            <FileSpreadsheet className="w-12 h-12 text-blue-500" />
            <p className="text-gray-600 text-center">
              This Excel file cannot be previewed in the browser. This can happen with older Excel formats or complex spreadsheets.
            </p>
            <p className="text-gray-600 text-center">
              Please click the "Download Excel" button above to open it in your preferred spreadsheet application.
            </p>
          </div>
        )}

        {data ? (
          <div
            ref={containerRef}
            className="overflow-auto max-h-[80vh] border border-gray-300"
          >
            <table className="table-auto w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-100 border-b">
                  {data[0].map((header, index) => (
                    <th key={index} className="px-4 py-2 border">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex} className="border-b">
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="px-4 py-2 border">
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </motion.div>
    </motion.div>
  );
};

export default ExcelViewer;