import React from "react";
import { X, Download, File } from "lucide-react";

interface PowerPointViewerProps {
  url: string;
  onClose: () => void;
  filename?: string;
}

const PowerPointViewer: React.FC<PowerPointViewerProps> = ({
  url,
  onClose,
  filename = "presentation",
}) => {
  const cleanUrl = url.replace(/\\\//g, "/");

  return (
    <div className="fixed inset-0 bg-black/50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-6 rounded-lg shadow-xl relative max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-lg font-semibold">View Presentation</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full"
            aria-label="Close"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="text-center p-8 space-y-6">
          <div className="flex justify-center">
            <File className="w-16 h-16 text-blue-500" />
          </div>

          <div className="space-y-2">
            <h3 className="text-lg font-medium">PowerPoint Viewer</h3>
            <p className="text-gray-600">
              PowerPoint files cannot be previewed directly in the browser for
              the best quality and functionality.
            </p>
          </div>

          <div className="pt-4">
            <a
              href={cleanUrl}
              download={filename}
              className="inline-flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              <Download className="w-5 h-5" />
              Download Presentation
            </a>
            <p className="text-sm text-gray-500 mt-2">
              If the download doesn't start automatically, click the button
              above.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PowerPointViewer;
