import React from "react";
import { useParams } from "react-router-dom";
import Man from "../components/reports/allSalesReps/Man";
import All from "../components/reports/allSalesReps/All";

const AllSalesReps: React.FC = () => {
  const { manager } = useParams();

  return (
    <section className="py-8">
      <Man manager={manager} />
      <All manager={manager}/>
    </section>
  );
};

export default AllSalesReps;
