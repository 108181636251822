import React, { useState, useEffect } from "react";
import {
  FaUserEdit,
  FaEye,
  FaEyeSlash,
  FaUser,
  FaEnvelope,
  FaLock,
  FaTimes,
} from "react-icons/fa";
import { toast } from "react-toastify";
import accessURL from "../../../../../../../components/utils/access";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";

interface PasswordValidation {
  hasUpperCase: boolean;
  hasLowerCase: boolean;
  hasNumber: boolean;
  hasSpecialChar: boolean;
  isMinLength: boolean;
}

interface UpdateProps {
  row: {
    name: string;
    email: string;
    ID: number;
    companyID: string;
    role: string;
  };
  mode: "manager" | "salesrep";
}

const Update: React.FC<UpdateProps> = ({ row, mode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);

  const [formData, setFormData] = useState({
    fullName: row.name || "",
    email: row.email || "",
    password: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    password: "",
  });

  const [passwordValidation, setPasswordValidation] =
    useState<PasswordValidation>({
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      isMinLength: false,
    });

  useEffect(() => {
    if (row) {
      setFormData((prev) => ({
        ...prev,
        fullName: row.name || "",
        email: row.email || "",
      }));
    }
  }, [row]);

  const validatePassword = (password: string) => {
    setPasswordValidation({
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /[0-9]/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      isMinLength: password.length >= 8,
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === "password") {
      validatePassword(value);
    }

    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (formData.password) {
      const isPasswordValid = Object.values(passwordValidation).every(
        (value) => value
      );
      if (!isPasswordValid) {
        newErrors.password = "Password does not meet requirements";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      try {
        const payload = {
          name: formData.fullName,
          email: formData.email,
          id: row.ID,
          ...(formData.password && { password: formData.password }),
        };

        const endpoint =
          mode === "manager"
            ? "admin/update?role=manager"
            : "admin/update?role=salesrep";

        const response = await accessURL.post(endpoint, payload);

        if (response.status === 201) {
          toast.success(
            `${
              mode === "manager" ? "manager" : "salesrep"
            } updated successfully!`,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          window.location.reload();
        }
      } catch (error) {
        toast.error(
          `Failed to update ${
            mode === "manager" ? "manager" : "sales representative"
          }`,
          {
            position: "top-center",
            autoClose: 3000,
          }
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="flex-1 bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded w-full transition-colors duration-300"
      >
        Update
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold flex items-center">
                  <FaUserEdit className="mr-2" /> Update{" "}
                  {mode === "manager" ? "Manager" : "Sales Rep"}
                </h2>
                <button
                  onClick={() => setIsOpen(false)}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <FaTimes className="text-xl" />
                </button>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4">
                  {/* Full Name Input */}
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <FaUser className="text-lg text-gray-600" />
                      <input
                        type="text"
                        name="fullName"
                        placeholder={`${
                          mode === "manager" ? "Manager" : "Sales Rep"
                        } Full Name`}
                        value={formData.fullName}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                      />
                    </div>
                    {errors.fullName && (
                      <p className="text-red-500 text-sm">{errors.fullName}</p>
                    )}
                  </div>

                  {/* Email Input */}
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2">
                      <FaEnvelope className="text-lg text-gray-600" />
                      <input
                        type="email"
                        name="email"
                        placeholder={`${
                          mode === "manager" ? "Manager" : "Sales Rep"
                        } Email`}
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                      />
                    </div>
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>

                  {/* Password Input */}
                  <div className="flex flex-col space-y-2">
                    <div className="flex items-center space-x-2 relative">
                      <FaLock className="text-lg text-gray-600" />
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="New Password (Optional)"
                        value={formData.password}
                        onChange={handleChange}
                        className="w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 pr-10"
                      />
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                      >
                        {showPassword ? (
                          <FaEyeSlash className="text-gray-500 hover:text-gray-700" />
                        ) : (
                          <FaEye className="text-gray-500 hover:text-gray-700" />
                        )}
                      </button>
                    </div>
                    {errors.password && (
                      <p className="text-red-500 text-sm">{errors.password}</p>
                    )}

                    {formData.password && (
                      <div className="text-sm space-y-1 mt-2">
                        <p className="font-medium text-gray-700">
                          Password requirements:
                        </p>
                        <ul className="space-y-1">
                          {Object.entries({
                            "Uppercase letter": passwordValidation.hasUpperCase,
                            "Lowercase letter": passwordValidation.hasLowerCase,
                            Number: passwordValidation.hasNumber,
                            "Special character":
                              passwordValidation.hasSpecialChar,
                            "Minimum 8 characters":
                              passwordValidation.isMinLength,
                          }).map(([label, valid], index) => (
                            <li
                              key={index}
                              className={`flex items-center space-x-2 ${
                                valid ? "text-green-600" : "text-gray-500"
                              }`}
                            >
                              <span
                                className={`w-3 h-3 rounded-full ${
                                  valid ? "bg-green-600" : "bg-gray-500"
                                }`}
                              />
                              <span>{label}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className={`w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors ${
                    isLoading ? "opacity-75 pointer-events-none" : ""
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Update;
