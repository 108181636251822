import React, { useEffect, useState } from "react";
import accessURL from "../../../../../../components/utils/access";
import { ApiResponse } from "../../../../../../salesRep/components/schedule/types/types";
import { Link, useParams } from "react-router-dom";
import { useDateFilter } from "../../../../../../contexts/dashboard/DateFilterContext";
import { useQuery } from "@tanstack/react-query";
import Det from "../Det";
import SalesRepMetrics from "../../salesrepinfo/SalesRepMetrics";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store";
import { ArrowLeft } from "lucide-react";

const metricsDashboardRep = async (
  startDate?: string,
  endDate?: string,
  year?: number,
  name?: string
): Promise<ApiResponse> => {
  const params = new URLSearchParams();
  if (startDate) params.append("start_date", startDate);
  if (endDate) params.append("end_date", endDate);
  if (year) params.append("year", year.toString());

  const res = await accessURL(
    `metrics/getMetrics?name=${name}&role=rep&${params.toString()}`
  );
  return {
    status: res.data.status,
    code: res.data.code,
    data: res.data.data,
  };
};

const RepReport: React.FC = () => {
  const { rep, salesRepID } = useParams();
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";

  
  const dateFilter = useDateFilter();

  const startDate = dateFilter?.startDate ?? "";
  const endDate = dateFilter?.endDate ?? "";
  const selectedYear = dateFilter?.selectedYear ?? new Date().getFullYear();

  const { data, isLoading } = useQuery({
    queryKey: ["metrics", startDate, endDate, selectedYear, rep],
    queryFn: () => metricsDashboardRep(startDate, endDate, selectedYear, rep),
  });

  return (
    <div>
      <div className="flex justify-between items-center p-4 lg:justify-end">
        <Link
          className="
      group inline-flex items-center gap-2 px-4 py-2 
      text-sm font-medium transition-all duration-200
      bg-blue-900 hover:bg-gray-50 
      text-white hover:text-gray-900
      rounded-lg border border-gray-200 hover:border-gray-300
      shadow-sm hover:shadow
      sm:text-base 
      lg:py-3 lg:px-6 lg:text-lg lg:font-bold
    "
          to={`/admin/${email}/report`}
        >
          <ArrowLeft className="w-4 h-4 sm:w-5 sm:h-5 transition-transform group-hover:-translate-x-0.5" />
        </Link>
      </div>

      <Det name={rep} />
      <SalesRepMetrics
        data={data}
        isLoading={isLoading}
        salesRepID={Number(salesRepID)}
      />
    </div>
  );
};

export default RepReport;
