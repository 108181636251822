import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight, FileText, UserRound } from "lucide-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";

interface SalesRep {
  salesRepID: number;
  salesRepName: string;
  totalReports: number;
}

interface SalesRepCardProps {
  rep: SalesRep;
  name?: String;
}

const RepCard: React.FC<SalesRepCardProps> = ({ name, rep }) => {
  const userInfo = useSelector((state: RootState) => state.user.user?.userInfo);
  const email = userInfo?.email || "";

  return (
    <div className="flex flex-col border rounded-lg p-4 shadow-sm">
      <div className="flex items-center mb-4">
        <div className="bg-blue-100 p-3 rounded-full">
          <UserRound className="w-6 h-6 text-blue-600" />
        </div>
        <h3 className="ml-3 text-lg font-semibold text-gray-800 capitalize">
          {rep.salesRepName}
        </h3>
      </div>

      <div className="flex items-center text-gray-600 mb-4">
        <FileText className="w-5 h-5 mr-2" />
        <span className="font-medium">{rep.totalReports} Reports</span>
      </div>

      <Link
        to={`/admin/${email}/report/${rep.salesRepName}/${rep.salesRepID}`}
        className="inline-flex items-center text-blue-600 hover:text-blue-700 transition-colors mt-auto"
      >
        View Reports
        <ArrowRight className="ml-2 w-4 h-4" />
      </Link>
    </div>
  );
};

export default RepCard;
