import React from "react";
import {   
  BarChart,   
  Bar,   
  XAxis,   
  YAxis,   
  Tooltip,   
  ResponsiveContainer,   
  Legend, 
} from "recharts"; 
import { Props } from "../../../../salesRep/components/schedule/types/types";  

const CustomerAnalytics: React.FC<Props> = ({ data, isLoading }) => {   
  if (isLoading) return (       
    <div className="flex items-center justify-center h-64">         
      <div className="animate-spin rounded-full h-12 w-12 border-4 border-blue-500 border-t-transparent"></div>       
    </div>     
  );    

  if (!data?.data?.customerAnalytics?.customerAnalytics.monthly_data) {     
    return <div>No data available</div>;   
  }    

  const { monthly_data, total_customers } =     
    data?.data?.customerAnalytics.customerAnalytics;    

  // Map data for the chart   
  const formattedData = monthly_data.map((item) => ({     
    ...item,     
    month: item.month.slice(0, 3),
    // Calculate percentage based on total_customers
    category_a_percent: total_customers > 0 
      ? ((item.category_a / total_customers) * 100) 
      : 0,
    category_b_percent: total_customers > 0 
      ? ((item.category_b / total_customers) * 100) 
      : 0,
    category_c_percent: total_customers > 0 
      ? ((item.category_c / total_customers) * 100) 
      : 0
  }));    

  return (     
    <div className="py-3 space-y-6">       
      <ResponsiveContainer width="100%" height={400}>         
        <BarChart data={formattedData} barCategoryGap="10%">           
          <XAxis             
            dataKey="month"             
            tick={{ fontSize: 12 }}             
            tickLine={false}             
            axisLine={{ stroke: "#ccc", strokeWidth: 1 }}           
          />           
          <YAxis             
            type="number"             
            domain={[0, 100]} // Always 0-100%            
            tickFormatter={(value) => `${value}%`}             
            tick={{ fontSize: 12 }}             
            tickLine={false}             
            axisLine={{ stroke: "#ccc", strokeWidth: 1 }}           
          />           
          <Tooltip             
            content={({ payload, label }) => {               
              if (!payload || payload.length === 0) return null;                
              const { 
                category_a, 
                category_b, 
                category_c, 
                total, 
                category_a_percent,
                category_b_percent,
                category_c_percent
              } = payload[0].payload;                
              return (                 
                <div className="bg-white p-3 rounded shadow-md text-sm text-gray-700">                   
                  <div><strong>{label}</strong></div>                   
                  <div>Total: {total}</div>                   
                  <div>Category A: {category_a} ({category_a_percent.toFixed(2)}%)</div>                   
                  <div>Category B: {category_b} ({category_b_percent.toFixed(2)}%)</div>                   
                  <div>Category C: {category_c} ({category_c_percent.toFixed(2)}%)</div>                 
                </div>               
              );             
            }}           
          />           
          <Legend />           
          <Bar             
            dataKey="category_a_percent"             
            stackId="a"             
            fill="#4CAF50"             
            name="Category A"             
            maxBarSize={100}           
          />           
          <Bar             
            dataKey="category_b_percent"             
            stackId="a"             
            fill="#FFC107"             
            name="Category B"             
            maxBarSize={100}           
          />           
          <Bar             
            dataKey="category_c_percent"             
            stackId="a"             
            fill="#F44336"             
            name="Category C"             
            maxBarSize={100}           
          />         
        </BarChart>       
      </ResponsiveContainer>           
    </div>   
  ); 
};  

export default CustomerAnalytics;