import React from "react";
import { Users } from "lucide-react";

const DescReport = () => {
  return (
    <div className="w-full p-4 sm:p-6 mb-6 rounded-lg shadow-md bg-gradient-to-r from-slate-50 to-slate-100">
      <div className="flex flex-col sm:flex-row sm:items-start gap-4">
        <div className="rounded-lg bg-blue-100 p-3 shrink-0">
          <Users className="h-6 w-6 text-blue-600" />
        </div>
        <div>
          <h2 className="text-2xl font-semibold text-slate-800 mb-2">
            Managers Performance Reports
          </h2>
          <p className="text-slate-600 leading-relaxed">
            Click on "View Reports" to access a comprehensive overview of all
            managers team metrics and performance indicators.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DescReport;
