import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { customStyles } from "../../../components/utils/utils";
import accessURL from "../../../components/utils/access";
import Actions from "./actions/Actions";
import FilterSection from "./FilterSection";

interface CustomerData {
  id: number;
  name: string;
  state: string;
  city: string;
  phone: string;
  email: string;
  category: string;
  companyID: string;
  nature: string;
  pop: string;
  planned: number;
  achieved: number;
  target: number;
  call_rate: number;
  created_at: string;
}

interface PaginationInfo {
  currentPage: number;
  totalPages: number;
  totalCustomers: number;
  limit: number;
}

interface Filters {
  startDate?: string;
  endDate?: string;
  search?: string;
  date?: boolean;
  name?: boolean;
  category?: boolean;
  city?: boolean;
  state?: boolean;
}

const Customers: React.FC = () => {
  const [data, setData] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filters, setFilters] = useState<Filters>({});

  console.log(filters);

  const fetchCustomers = async (page: number, pageSize: number) => {
    setLoading(true);

    const params: Record<string, string> = {};

    // Only add defined and non-empty string values to params
    if (filters.startDate) params.start_date = filters.startDate;
    if (filters.endDate) params.end_date = filters.endDate;
    if (filters.search) params.name = filters.search;
    // if (filters.name !== undefined) params.customerName = String(filters.name);
    if (filters.category !== undefined)
      params.category = String(filters.category);
    if (filters.city !== undefined) params.city = String(filters.city);
    if (filters.state !== undefined) params.state = String(filters.state);

    const queryParams = new URLSearchParams(params);

    let url = `customers?page=${page}&limit=${pageSize}${
      queryParams.toString() ? `&${queryParams.toString()}` : ""
    }`;

    try {
      const response = await accessURL.get<{
        data: CustomerData[];
        pagination: PaginationInfo;
      }>(url);
      setData(response.data.data);
      setTotalRows(response.data.pagination.totalCustomers);
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(currentPage, perPage);
  }, [currentPage, perPage, filters]);

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const getCategoryStyle = (category: string) => {
    switch (category.toUpperCase()) {
      case "A":
        return "bg-green-500 text-white font-bold py-1 px-2 rounded";
      case "B":
        return "bg-yellow-500 text-white font-bold py-1 px-2 rounded";
      case "C":
        return "bg-black text-white font-bold py-1 px-2 rounded";
      default:
        return "bg-gray-500 text-white font-bold py-1 px-2 rounded";
    }
  };

  const columns: TableColumn<CustomerData>[] = [
    {
      name: "S/N",
      selector: (row) => row.id,
      format: (_, index) => (currentPage - 1) * perPage + index + 1,
      width: "70px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      width: "270px",
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      width: "170px",
    },

    {
      name: "Email",
      selector: (row) => row.email,
      width: "270px",
    },
    {
      name: "Place of Practice",
      selector: (row) => row.pop,
      width: "200px",
    },
    {
      name: "Nature of Business",
      selector: (row) => row.nature,
      width: "200px",
    },
    {
      name: "planned visits",
      selector: (row) => row.planned,
      width: "200px",
    },
    {
      name: "achieved",
      selector: (row) => row.achieved,
      width: "100px",
    },
    {
      name: "target",
      selector: (row) => row.target,
      width: "100px",
    },
    {
      name: "call_rate",
      selector: (row) => row.call_rate,
      width: "200px",
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      width: "170px",
      cell: (row) => (
        <span className={getCategoryStyle(row.category)}>{row.category}</span>
      ),
    },
    {
      name: "Date Created",
      selector: (row) => {
        const date = new Date(row.created_at);
        return new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(date);
      },
      wrap: true,
      width: "200px",
    },
    {
      name: "Action",
      width: "270px",
      cell: (row) => (
        <div>
          <Actions id={row.id} name={row.name} customerData={row} />
        </div>
      ),
    },
  ];

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      <div className="py-6">
        <FilterSection onFilterChange={handleFilterChange} />
      </div>

      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        paginationPerPage={perPage}
        striped
        highlightOnHover
        responsive
        persistTableHead
        customStyles={customStyles}
        noDataComponent={
          <div className="p-4 text-center text-gray-500">
            No Customers found
          </div>
        }
      />
    </div>
  );
};

export default Customers;
