import React, { useState, useEffect } from "react";
import accessURL from "../../../../../components/utils/access";
import ContentItem from "./ContentItem";
import "./scrollbar.css";
import WordViewer from "./docpreviewer/Mammoth";
import PDFViewer from "./docpreviewer/PDFViewer";
import ExcelViewer from "./docpreviewer/ExcelViewer";
import PowerPointViewer from "./docpreviewer/PowerPointViewer";
import FilterSection from "./FilterSection";

type Content = {
  id: number;
  name: string;
  description: string;
  purpose: string;
  companyID?: string;
  companyName?: string;
  file_path?: string;
  file_name?: string;
  file_size?: string;
  file_type?: string;
  upload_date?: string;
};

interface Filters {
  startDate?: string;
  endDate?: string;
  date?: boolean;
  search?: string;
  name?: boolean;
}

type ContentWithPreview = Content & {
  openPreview: (filePath: string) => void;
};

const Contents: React.FC = () => {
  const [contents, setContents] = useState<ContentWithPreview[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [filters, setFilters] = useState<Filters>({});
  // console.log(filters);

  const params = {
    start_date: filters.startDate,
    end_date: filters.endDate,
    name: filters.search,
    date: filters.date,
    contentName: filters.name,
  };

  const fetchContents = async (page = 1) => {
    setIsLoading(true);

    try {
      const response = await accessURL.get(`contents?page=${page}`, { params });
      const fetchedContents: ContentWithPreview[] = response.data.data.map(
        (item: Content) => ({
          ...item,
          openPreview: (filePath: string) => openPreview(filePath),
        })
      );
      setContents(fetchedContents);
      setCurrentPage(response.data.pagination.currentPage);
      setTotalPages(response.data.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching contents:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchContents();
  }, [currentPage, filters]);

  const handlePageChange = (page: number) => {
    fetchContents(page);
  };

  const openPreview = (filePath: string) => {
    setPreviewUrl(filePath);
  };

  const closePreview = () => {
    setPreviewUrl(null);
  };

  const getFileType = (filePath: string | null): string | null => {
    if (!filePath) return null;

    const extension = filePath.split(".").pop()?.toLowerCase();
    return extension ?? null;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleFilterChange = (newFilters: Filters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  return (
    <div className="space-y-4">
      <div>
        <FilterSection onFilterChange={handleFilterChange} />
      </div>

      {contents.length === 0 && <div>No contents found</div>}

      <div className="grid grid-cols-1 gap-4 max-h-[800px] overflow-y-auto  scrollbar-custom sm:grid-cols-2 lg:grid-cols-3">
        {contents.map((content, index) => (
          <ContentItem key={content.id} content={content} index={index} />
        ))}
      </div>

      {/* Conditional rendering based on file type */}
      {previewUrl && (
        <>
          {(() => {
            const fileType = getFileType(previewUrl);

            if (fileType === "pdf") {
              return <PDFViewer url={previewUrl} onClose={closePreview} />;
            }

            if (fileType === "docx" || fileType === "doc") {
              return <WordViewer url={previewUrl} onClose={closePreview} />;
            }

            if (fileType === "pptx" || fileType === "ppt") {
              return (
                <PowerPointViewer url={previewUrl} onClose={closePreview} />
              );
            }

            if (fileType === "xlsx" || fileType === "xls") {
              return <ExcelViewer url={previewUrl} onClose={closePreview} />;
            }
          })()}
        </>
      )}

      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`mx-1 px-3 py-1 rounded ${
              currentPage === page ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Contents;
