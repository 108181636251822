import React from "react";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import SetTarget from "./SetTarget";



const Target: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <div>
      <SetTarget data={data} isLoading={isLoading} />
    </div>
  );
};

export default Target;
