import React, { useState } from "react";
import accessURL from "../../../../components/utils/access";
import UpdateCustomerModal from "../updateCustomers/UpdateCustomerModal";
import { toast } from "react-toastify";

interface CustomerData {
  id: number;
  name: string;
  state: string;
  city: string;
  phone: string;
  email: string;
  category: string;
  companyID: string;
  nature: string;
  pop: string;
}

interface CustomerActionProps {
  id: number;
  name: string;
  customerData: CustomerData;
}

const Actions: React.FC<CustomerActionProps> = ({ id, name, customerData }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actionType, setActionType] = useState<"update" | "delete" | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteCustomer = async () => {
    const response = await accessURL.post(`/customers/delete?id=${id}`);

    if (
      response.data &&
      response.data.status === "success" &&
      response.data.code === 200
    ) {
      setShowConfirmModal(false);
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
        window.location.reload();
      }, 2000);
    }
  };

  const handleUpdate = async (formData: FormData) => {
    setIsLoading(true);

    const response = await accessURL.post(
      `/customers/update?id=${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200) {
      toast.success("Customer updated successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }

    setIsLoading(false);
  };
  const openConfirmModal = (type: "update" | "delete") => {
    setActionType(type);
    setShowConfirmModal(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="relative">
      <div className="flex space-x-4">
        <button
          onClick={handleOpenModal}
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
        >
          Update
        </button>
        <button
          onClick={() => openConfirmModal("delete")}
          className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 text-sm"
        >
          Delete
        </button>
      </div>

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}
        >
          <div className="bg-white p-6 rounded-lg shadow-xl">
            <h2 className="text-xl font-bold mb-4">Are you sure?</h2>
            <p className="mb-4">
              {actionType === "delete"
                ? "This action is irreversible. Do you want to delete this customer?"
                : "Do you want to update this customer's information?"}
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmModal(false)}
                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteCustomer}
                className={`px-4 py-2 text-white rounded-md ${
                  actionType === "delete"
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-blue-500 hover:bg-blue-600"
                }`}
              >
                {actionType === "delete" ? "Delete" : "Update"}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Success Modal */}
      {showSuccessModal && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-6 py-3 rounded-md shadow-lg z-50">
          {actionType === "delete"
            ? "Deleted successfully"
            : "Updated successfully"}
        </div>
      )}

      <UpdateCustomerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onUpdate={handleUpdate}
        isLoading={isLoading}
        customerData={customerData}
      />
    </div>
  );
};

export default Actions;
