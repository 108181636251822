import { useState } from "react";
import Cards from "../../../../manager/components/dashboard/cards/Cards";
import CustomerAnalytics from "../../../../manager/components/dashboard/customer_analytics/CustomerAnalytics";
import CustomerInfo from "../../../../manager/components/dashboard/customer_info/CustomerInfo";
import Target from "../../../../manager/components/dashboard/target/Target";
import VisitAnalytics from "../../../../manager/components/dashboard/visit_analytics/VisitAnalytics";
import { Props } from "../../../../salesRep/components/schedule/types/types";
import DateRangePicker from "../../dashboard/customs/DateRangePicker";
import YearSelector from "../../../../manager/components/dashboard/customs/YearSelector";
import SalesRepsHalf from "../single_reports/salesrepinfo/SalesRepsHalf";

interface AdditionalProps {
  name?: string;
}

type CombinedProps = Props & AdditionalProps;

const ManagerMetrics: React.FC<CombinedProps> = ({ name, data, isLoading }) => {
  const [activeTab, setActiveTab] = useState<"visit" | "customer">("visit");
  return (
    <div>
      <div className="flex items-center space-x-4 mt-4 py-4 lg:mt-0">
        <DateRangePicker />
      </div>
      <Cards data={data} isLoading={isLoading} />
      <CustomerInfo data={data} isLoading={isLoading} />
      <Target data={data} isLoading={isLoading} />
      <section className="w-full  bg-white rounded-lg shadow-sm">
        <div className="mt-20">
          <div className="px-2">
            <YearSelector />
          </div>

          <div className="flex space-x-4 my-8 px-4">
            <button
              className={`px-6 py-2 rounded-md ${
                activeTab === "visit"
                  ? "bg-black text-white font-bold"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("visit")}
            >
              Visit Analytics
            </button>
            <button
              className={`px-6 py-2 rounded-md ${
                activeTab === "customer"
                  ? "bg-black text-white font-bold"
                  : "bg-gray-200"
              }`}
              onClick={() => setActiveTab("customer")}
            >
              Customer Analytics
            </button>
          </div>

          {activeTab === "visit" ? (
            <VisitAnalytics data={data} isLoading={isLoading} />
          ) : (
            <div className="bg-gray-50 rounded-lg border border-gray-100">
              <CustomerAnalytics data={data} isLoading={isLoading} />
            </div>
          )}

          <SalesRepsHalf name={name} />
          <div className="h-64 bg-gray-50 rounded-lg border border-gray-100">
            top visits and top sales reps
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManagerMetrics;
